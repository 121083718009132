import React from 'react';
import {
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&.description': {
    width: '40%',
  },
  '&.number': {
    width: '15%',
  },
  '&.action': {
    width: '10%',
  },
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    fontWeight: 600
  }
}));

const MobileInputGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(2),
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  }
}));

const InvoiceLineItems = ({ lineItems, handleLineItemChange, addLineItem, removeLineItem }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleAddLineItem = () => {
    addLineItem({
      classification: "008",
      description: "",
      quantity: 1,
      unit_price: 0.0,
      total: 0.0,
      tax_amount: 0.0,
    });
  };

  const renderDesktopView = () => (
    <TableContainer sx={{
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 1,
      mb: 2
    }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell className="description">Description</StyledTableCell>
            <StyledTableCell className="number">Qty</StyledTableCell>
            <StyledTableCell className="number">Unit Price</StyledTableCell>
            <StyledTableCell className="number">Total</StyledTableCell>
            <StyledTableCell className="number">Tax Amount</StyledTableCell>
            <StyledTableCell className="action">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItems.map((item, index) => (
            <TableRow 
              key={index} 
              sx={{
                '&:nth-of-type(even)': { 
                  bgcolor: 'action.hover'
                }
              }}
            >
              <StyledTableCell>
                <TextField
                  fullWidth
                  size="small"
                  name="item_description"
                  value={item.description}
                  onChange={(e) => handleLineItemChange(index, 'description', e.target.value)}
                  placeholder="Laptop Peripherals"
                  required
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  name="quantity"
                  value={item.quantity}
                  onChange={(e) => handleLineItemChange(index, 'quantity', parseFloat(e.target.value))}
                  inputProps={{ step: "1" }}
                  placeholder="1"
                  required
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  name="unit_price"
                  value={item.unit_price}
                  onChange={(e) => handleLineItemChange(index, 'unit_price', parseFloat(e.target.value))}
                  inputProps={{ step: "0.01" }}
                  placeholder="1436.50"
                  required
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  name="total"
                  value={item.total}
                  disabled
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  name="tax_amount"
                  value={item.tax_amount}
                  disabled
                />
              </StyledTableCell>
              <StyledTableCell>
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={() => removeLineItem(index)}
                  disabled={lineItems.length === 1}
                  sx={{
                    minWidth: 'auto',
                    p: 1
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </Button>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderMobileView = () => (
    <Box>
      {lineItems.map((item, index) => (
        <MobileInputGroup key={index}>
          <Typography variant="subtitle2" fontWeight={600}>
            Line Item {index + 1}
          </Typography>
          <TextField
            fullWidth
            label="Description"
            size="small"
            name="item_description"
            value={item.description}
            onChange={(e) => handleLineItemChange(index, 'description', e.target.value)}
            placeholder="Laptop Peripherals"
            required
          />
          <TextField
            fullWidth
            label="Quantity"
            size="small"
            type="number"
            name="quantity"
            value={item.quantity}
            onChange={(e) => handleLineItemChange(index, 'quantity', parseFloat(e.target.value))}
            inputProps={{ step: "1" }}
            placeholder="1"
            required
          />
          <TextField
            fullWidth
            label="Unit Price"
            size="small"
            type="number"
            name="unit_price"
            value={item.unit_price}
            onChange={(e) => handleLineItemChange(index, 'unit_price', parseFloat(e.target.value))}
            inputProps={{ step: "0.01" }}
            placeholder="1436.50"
            required
          />
          <TextField
            fullWidth
            label="Total"
            size="small"
            type="number"
            name="total"
            value={item.total}
            disabled
          />
          <TextField
            fullWidth
            label="Tax Amount"
            size="small"
            type="number"
            name="tax_amount"
            value={item.tax_amount}
            disabled
          />
          <Button
            variant="outlined"
            color="error"
            onClick={() => removeLineItem(index)}
            disabled={lineItems.length === 1}
            startIcon={<DeleteIcon />}
          >
            Remove Item
          </Button>
        </MobileInputGroup>
      ))}
    </Box>
  );

  return (
    <Paper sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Invoice Line Items
      </Typography>

      {isMobile ? renderMobileView() : renderDesktopView()}

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={handleAddLineItem}
          startIcon={<AddIcon />}
          color="primary"
        >
          Add Line Item
        </Button>
      </Box>
    </Paper>
  );
};

export default InvoiceLineItems;

import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { apiRequest } from '../../utils/apiConfig';
import {
    Typography,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    Alert,
    CircularProgress,
    Card,
    CardContent,
    InputAdornment,
    IconButton,
    Stack,
    styled,
    alpha,
} from '@mui/material';
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Cancel as CancelIcon,
    Search as SearchIcon,
    Clear as ClearIcon,
    Phone as PhoneIcon,
    PeopleAltRounded as PeopleIcon,
} from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';

const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    transition: 'all 200ms ease',
    '&:hover': {
        borderColor: theme.palette.primary.main,
    },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,
    width: 'fit-content',
}));

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: alpha(theme.palette.primary.main, 0.05),
    },
    '& .MuiDataGrid-row:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.02),
    },
}));

const ManageCustomers = () => {
    const { currentUser } = useAuth();
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editingCustomer, setEditingCustomer] = useState(null);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const emptyCustomerForm = {
        customer_name: '',
        customer_tin: '',
        customer_biz_id_type: 'BRN',
        customer_biz_id_value: '',
        customer_sst_no: '',
        customer_address: '',
        customer_state: '14',
        customer_city: '',
        customer_country: 'MYS',
        customer_tel: ''
    };

    const [formData, setFormData] = useState(emptyCustomerForm);

    useEffect(() => {
        loadCustomers();
    }, [currentUser]);

    const loadCustomers = async () => {
        try {
            const response = await apiRequest('user-preference/customers', {
                method: 'GET'
            });
            if (response.success) {
                setCustomers(response.data || []);
            } else {
                throw new Error(response.error || 'Failed to load customers');
            }
            setLoading(false);
        } catch (err) {
            setError(err.message || 'Failed to load customers');
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let response;
            if (editingCustomer) {
                response = await apiRequest(`user-preference/customers/${editingCustomer.id}`, {
                    method: 'PUT',
                    body: {
                        customerName: formData.customer_name,
                        customerData: formData
                    }
                });
            } else {
                response = await apiRequest('user-preference/customers', {
                    method: 'POST',
                    body: {
                        customerName: formData.customer_name,
                        customerData: formData
                    }
                });
            }

            if (!response.success) {
                throw new Error(response.error || 'Failed to save customer');
            }

            await loadCustomers();
            resetForm();
        } catch (err) {
            setError(err.message || 'Failed to save customer');
        }
    };

    const handleEdit = (customer) => {
        setEditingCustomer(customer);
        setFormData(customer.preference_value);
        setIsFormVisible(true);
    };

    const handleDelete = async (customerId) => {
        if (window.confirm('Are you sure you want to delete this customer?')) {
            try {
                const response = await apiRequest(`user-preference/customers/${customerId}`, {
                    method: 'DELETE'
                });

                if (!response.success) {
                    throw new Error(response.error || 'Failed to delete customer');
                }

                await loadCustomers();
            } catch (err) {
                setError(err.message || 'Failed to delete customer');
            }
        }
    };

    const resetForm = () => {
        setFormData(emptyCustomerForm);
        setEditingCustomer(null);
        setIsFormVisible(false);
        setError(null);
    };

    const handleClearSearch = () => {
        setSearchTerm('');
    };

    const filteredCustomers = customers.filter(customer => {
        const searchFields = [
            customer.preference_value.customer_name,
            customer.preference_value.customer_tel,
            customer.preference_value.customer_address,
            customer.preference_value.customer_city,
        ];
        return searchFields.some(field => 
            field && field.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const columns = [
        { 
            field: 'customer_name', 
            headerName: 'Company Name', 
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <Typography variant="body2" noWrap>
                    {params.value}
                </Typography>
            ),
        },
        {
            field: 'phone',
            headerName: 'Contact',
            flex: 0.7,
            minWidth: 150,
            renderCell: (params) => (
                <Stack direction="row" spacing={1} alignItems="center">
                    <PhoneIcon fontSize="small" color="action" />
                    <Typography variant="body2" noWrap>
                        {params.value}
                    </Typography>
                </Stack>
            ),
        },
        { 
            field: 'address', 
            headerName: 'Address', 
            flex: 2,
            minWidth: 250,
            renderCell: (params) => (
                <Typography variant="body2" noWrap>
                    {params.value}
                </Typography>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            sortable: false,
            renderCell: (params) => (
                <Stack direction="row" spacing={1}>
                    <IconButton
                        size="small"
                        onClick={() => handleEdit(params.row.original)}
                        color="primary"
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={() => handleDelete(params.row.original.id)}
                        color="error"
                    >
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            ),
        },
    ];

    const rows = filteredCustomers.map(customer => ({
        id: customer.id,
        customer_name: customer.preference_value.customer_name,
        phone: customer.preference_value.customer_tel,
        address: `${customer.preference_value.customer_address}, ${customer.preference_value.customer_city}`,
        original: customer
    }));

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                <Stack spacing={1}>
                    <Typography variant="h4" component="h1">
                        Manage Customers
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Add and manage your customer information
                    </Typography>
                </Stack>
                <Button
                    variant="contained"
                    startIcon={isFormVisible ? <CancelIcon /> : <AddIcon />}
                    onClick={() => setIsFormVisible(!isFormVisible)}
                >
                    {isFormVisible ? 'Cancel' : 'Add Customer'}
                </Button>
            </Stack>

            {error && (
                <Alert severity="error">
                    {error}
                </Alert>
            )}

            {isFormVisible && (
                <StyledCard variant="outlined">
                    <CardContent>
                        <Stack spacing={3}>
                            <IconWrapper>
                                <PeopleIcon />
                            </IconWrapper>
                            <Typography variant="h6" component="h2">
                                {editingCustomer ? 'Edit Customer' : 'Add New Customer'}
                            </Typography>
                            <Box
                                component="form"
                                onSubmit={handleSubmit}
                                sx={{
                                    display: 'grid',
                                    gap: 3,
                                    gridTemplateColumns: {
                                        xs: '1fr',
                                        sm: 'repeat(2, 1fr)'
                                    }
                                }}
                            >
                                <TextField
                                    fullWidth
                                    label="Company/Customer Name"
                                    name="customer_name"
                                    value={formData.customer_name}
                                    onChange={handleInputChange}
                                    required
                                />
                                <TextField
                                    fullWidth
                                    label="Tax ID (TIN)"
                                    name="customer_tin"
                                    value={formData.customer_tin}
                                    onChange={handleInputChange}
                                    required
                                />
                                <FormControl fullWidth>
                                    <InputLabel>Business ID Type</InputLabel>
                                    <Select
                                        name="customer_biz_id_type"
                                        value={formData.customer_biz_id_type}
                                        onChange={handleInputChange}
                                        required
                                        label="Business ID Type"
                                    >
                                        <MenuItem value="NRIC">NRIC</MenuItem>
                                        <MenuItem value="PASSPORT">Passport</MenuItem>
                                        <MenuItem value="BRN">Business Registration No. (SSM)</MenuItem>
                                        <MenuItem value="ARMY">Army</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    label="Business ID Value"
                                    name="customer_biz_id_value"
                                    value={formData.customer_biz_id_value}
                                    onChange={handleInputChange}
                                    required
                                />
                                <TextField
                                    fullWidth
                                    label="SST No"
                                    name="customer_sst_no"
                                    value={formData.customer_sst_no}
                                    onChange={handleInputChange}
                                    required
                                />
                                <TextField
                                    fullWidth
                                    label="Phone"
                                    name="customer_tel"
                                    value={formData.customer_tel}
                                    onChange={handleInputChange}
                                    required
                                />
                                <Box sx={{ gridColumn: '1 / -1' }}>
                                    <TextField
                                        fullWidth
                                        label="Address"
                                        name="customer_address"
                                        value={formData.customer_address}
                                        onChange={handleInputChange}
                                        required
                                        multiline
                                        rows={3}
                                    />
                                </Box>
                                <FormControl fullWidth>
                                    <InputLabel>State</InputLabel>
                                    <Select
                                        name="customer_state"
                                        value={formData.customer_state}
                                        onChange={handleInputChange}
                                        required
                                        label="State"
                                    >
                                        <MenuItem value="01">Johor</MenuItem>
                                        <MenuItem value="02">Kedah</MenuItem>
                                        <MenuItem value="03">Kelantan</MenuItem>
                                        <MenuItem value="04">Melaka</MenuItem>
                                        <MenuItem value="05">Negeri Sembilan</MenuItem>
                                        <MenuItem value="06">Pahang</MenuItem>
                                        <MenuItem value="07">Pulau Pinang</MenuItem>
                                        <MenuItem value="08">Perak</MenuItem>
                                        <MenuItem value="09">Perlis</MenuItem>
                                        <MenuItem value="10">Selangor</MenuItem>
                                        <MenuItem value="11">Terengganu</MenuItem>
                                        <MenuItem value="12">Sabah</MenuItem>
                                        <MenuItem value="13">Sarawak</MenuItem>
                                        <MenuItem value="14">Wilayah Persekutuan Kuala Lumpur</MenuItem>
                                        <MenuItem value="15">Wilayah Persekutuan Labuan</MenuItem>
                                        <MenuItem value="16">Wilayah Persekutuan Putrajaya</MenuItem>
                                        <MenuItem value="17">Not Applicable</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    label="City"
                                    name="customer_city"
                                    value={formData.customer_city}
                                    onChange={handleInputChange}
                                    required
                                />
                                <FormControl fullWidth>
                                    <InputLabel>Country</InputLabel>
                                    <Select
                                        name="customer_country"
                                        value={formData.customer_country}
                                        onChange={handleInputChange}
                                        required
                                        label="Country"
                                    >
                                        <MenuItem value="MYS">Malaysia</MenuItem>
                                    </Select>
                                </FormControl>
                                <Stack direction="row" spacing={2} sx={{ gridColumn: '1 / -1' }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        {editingCustomer ? 'Update Customer' : 'Add Customer'}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={resetForm}
                                    >
                                        Cancel
                                    </Button>
                                </Stack>
                            </Box>
                        </Stack>
                    </CardContent>
                </StyledCard>
            )}

            <StyledCard variant="outlined">
                <CardContent>
                    <Stack spacing={3}>
                        <TextField
                            fullWidth
                            placeholder="Search customers..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: searchTerm && (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClearSearch}
                                            edge="end"
                                            size="small"
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Box sx={{ height: 600 }}>
                            <StyledDataGrid
                                rows={rows}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[10, 25, 50]}
                                disableSelectionOnClick
                                density="comfortable"
                            />
                        </Box>
                    </Stack>
                </CardContent>
            </StyledCard>
        </Stack>
    );
};

export default ManageCustomers;

import React from 'react';
import {
  Paper,
  Typography,
  TextField,
  Box
} from '@mui/material';

const InvoiceTaxInfo = ({ formData, handleInputChange }) => {
  return (
    <Paper 
      sx={{
        p: 3,
        mb: 3,
        display: 'none'
      }}
    >
      <Typography variant="h6" sx={{ mb: 3 }}>
        Tax Setting
      </Typography>
      
      <Box sx={{ 
        display: 'grid', 
        gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
        gap: 3
      }}>
        <TextField
          id="global-tax-category"
          name="global_tax_category"
          label="Tax Category"
          value={formData.global_tax_category}
          onChange={handleInputChange}
          required
        />

        <TextField
          id="global-tax-percentage"
          name="global_tax_percentage"
          label="Tax Percentage (%)"
          type="number"
          value={formData.global_tax_percentage}
          onChange={handleInputChange}
          required
          inputProps={{
            step: "0.01"
          }}
        />
      </Box>
    </Paper>
  );
};

export default InvoiceTaxInfo;

import React, { useState, useEffect } from 'react';
import { apiRequest } from '../utils/apiConfig';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  Alert,
  List,
  ListItem,
  Card,
  FormControl,
  InputLabel,
  Stack,
  styled,
  alpha,
} from '@mui/material';
import {
  Close as CloseIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  VpnKey as KeyIcon,
} from '@mui/icons-material';

const KEY_OPTIONS = [
  {
    name: "LHDN_MYINVOIS_CLIENT_ID_STAGING",
    description: "LHDN MyInvois Client ID"
  },
  {
    name: "LHDN_MYINVOIS_CLIENT_SECRET_STAGING",
    description: "LHDN MyInvois Client Secret Key"
  }
];

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

const ContentWrapper = styled(Card, {
  shouldForwardProp: prop => prop !== 'isPage'
})(({ theme, isPage }) => ({
  position: 'relative',
  width: '90%',
  maxWidth: 600,
  maxHeight: isPage ? 'none' : '90vh',
  overflow: 'auto',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: isPage ? 'none' : theme.shadows[1],
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  color: theme.palette.primary.main,
  width: 'fit-content',
}));

const KeyItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  transition: 'all 200ms ease',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.02),
  },
}));

const UserKeysModal = ({ isOpen, onClose, isPage = false }) => {
  const [userKeys, setUserKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedKey, setSelectedKey] = useState(null);
  const [formData, setFormData] = useState({
    key_name: '',
    key_value: '',
  });

  useEffect(() => {
    if (isPage || isOpen) {
      fetchUserKeys();
    }
  }, [isPage, isOpen]);

  const fetchUserKeys = async () => {
    try {
      setLoading(true);
      const response = await apiRequest('/api/user-keys');
      if (response.data) {
        setUserKeys(response.data);
      }
    } catch (err) {
      setError('Failed to fetch keys');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      setLoading(true);
      if (selectedKey) {
        await apiRequest(`/api/user-keys/${selectedKey.id}`, {
          method: 'PUT',
          body: formData
        });
      } else {
        const existingKey = userKeys.find(k => k.key_name === formData.key_name);
        if (existingKey) {
          setError('You already have this type of key. Please update the existing one instead.');
          return;
        }

        await apiRequest('/api/user-keys', {
          method: 'POST',
          body: {
            ...formData,
            key_desc: KEY_OPTIONS.find(k => k.name === formData.key_name)?.description
          }
        });
      }

      setFormData({ key_name: '', key_value: '' });
      setSelectedKey(null);
      await fetchUserKeys();
    } catch (err) {
      setError(err.message || 'Failed to save key');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (keyId) => {
    if (!window.confirm('Are you sure you want to delete this key?')) return;

    try {
      setLoading(true);
      await apiRequest(`/api/user-keys/${keyId}`, {
        method: 'DELETE'
      });
      await fetchUserKeys();
    } catch (err) {
      setError('Failed to delete key');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (key) => {
    try {
      setLoading(true);
      const response = await apiRequest(`/api/user-keys/id/${key.id}`);
      if (response.data) {
        setSelectedKey(key);
        setFormData({
          key_name: response.data.key_name,
          key_value: response.data.key_value
        });
      }
    } catch (err) {
      setError('Failed to load key details');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const content = (
    <ContentWrapper isPage={isPage}>
      <Stack spacing={3}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Stack spacing={1}>
            <IconWrapper>
              <KeyIcon />
            </IconWrapper>
            <Typography variant="h5" component="h2" id="user-keys-modal-title">
              {selectedKey ? 'Edit API Key' : 'Add New API Key'}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Manage your API keys for LHDN MyInvois System (STAGING SERVER)
            </Typography>
          </Stack>
          {!isPage && onClose && (
            <IconButton onClick={onClose} size="small" color="default">
              <CloseIcon />
            </IconButton>
          )}
        </Box>

        {error && (
          <Alert severity="error">
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <FormControl fullWidth>
              <InputLabel id="key-type-label">Key Type</InputLabel>
              <Select
                labelId="key-type-label"
                value={formData.key_name}
                onChange={(e) => setFormData({ ...formData, key_name: e.target.value })}
                disabled={loading || selectedKey}
                required
                label="Key Type"
              >
                <MenuItem value="">
                  <em>Select a key type</em>
                </MenuItem>
                {KEY_OPTIONS.map(option => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              type="password"
              label="Key Value"
              value={formData.key_value}
              onChange={(e) => setFormData({ ...formData, key_value: e.target.value })}
              disabled={loading}
              required
              fullWidth
            />

            <Stack direction="row" spacing={2}>
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                fullWidth
              >
                {loading ? 'Saving...' : (selectedKey ? 'Update Key' : 'Add Key')}
              </Button>
              {selectedKey && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setSelectedKey(null);
                    setFormData({ key_name: '', key_value: '' });
                  }}
                >
                  Cancel
                </Button>
              )}
            </Stack>
          </Stack>
        </form>

        <Box>
          <Typography variant="h6">
            Your API Keys
          </Typography>
          <List>
            {userKeys.map(key => (
              <KeyItem key={key.id} disablePadding>
                <Stack spacing={0.5} py={2} px={1}>
                  <Typography variant="subtitle1">
                    {KEY_OPTIONS.find(k => k.name === key.key_name)?.description}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {key.key_name}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <IconButton
                    size="small"
                    onClick={() => handleEdit(key)}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => handleDelete(key.id)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              </KeyItem>
            ))}
          </List>
        </Box>
      </Stack>
    </ContentWrapper>
  );

  if (isPage) {
    return content;
  }

  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="user-keys-modal-title"
    >
      {content}
    </StyledModal>
  );
};

export default UserKeysModal;

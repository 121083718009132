import React from 'react';
import {
  Stack,
  Typography,
  Button,
  Box
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

const DocumentDetailsSection = ({
  documentDetails,
  isLoading,
  handleGetDocumentDetails
}) => {
  return (
    <Stack spacing={3}>
      <Typography variant="body1" color="text.secondary">
        If the submission was successful, you can click the button below to get the link from LHDN to the validated invoice. This is the link that should be given to customers who request it
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button 
          onClick={handleGetDocumentDetails}
          variant="contained" 
          disabled={isLoading}
          size="large"
          sx={{ minWidth: 200 }}
        >
          {isLoading ? 'Getting...' : 'Get Invoice Link'}
        </Button>
      </Box>
      
      {documentDetails?.shareableLink && (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom>
            Document Link
          </Typography>

          <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
            Click the link below to view your validated document:
          </Typography>

          <Button
            variant="contained"
            color="primary"
            href={documentDetails.shareableLink}
            target="_blank"
            rel="noopener noreferrer"
            endIcon={<LaunchIcon />}
          >
            View Document
          </Button>
        </Box>
      )}
    </Stack>
  );
};

export default DocumentDetailsSection;

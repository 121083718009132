import React from 'react';
import {
  Paper,
  FormControl,
  Select,
  InputLabel,
  FormHelperText,
  Typography,
  TextField,
  MenuItem,
  Box,
  Tooltip,
  IconButton
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const InvoiceMainInfo = ({ formData, handleInputChange, errors }) => {
  return (
    <Paper sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Invoice Details
      </Typography>
      
      <Box sx={{ 
        display: 'grid', 
        gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
        gap: 3
      }}>
        <Box>
          <TextField
            fullWidth
            id="invoice-id"
            name="invoice_id"
            label="Invoice ID"
            value={formData.invoice_id}
            onChange={handleInputChange}
            required
            slotProps={{
              input: {
                endAdornment: (
                  <Tooltip title="Input the same invoice number as your internal finance system for easy reference later on">
                    <IconButton size="small" color="inherit">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )
              }
            }}
          />
        </Box>

        <Box>
          <TextField
            fullWidth
            id="issue-date"
            name="issue_date"
            label="Issue Date"
            type="date"
            value={formData.issue_date}
            onChange={handleInputChange}
            required
            slotProps={{
              inputLabel: {
                shrink: true
              }
            }}
          />
        </Box>

        <Box sx={{ display: 'none' }}>
          <FormControl fullWidth required>
            <InputLabel htmlFor="idcurrency">Currency Code</InputLabel>
            <Select
              id="idcurrency"
              name="namecurrency"
              value={formData.currency}
              onChange={handleInputChange}
            >
              <MenuItem value="">Select currency</MenuItem>
              <MenuItem value="MYR">MYR (Malaysian Ringgit)</MenuItem>
              <MenuItem value="USD">USD (US Dollar)</MenuItem>
            </Select>
            <FormHelperText>
              Select the currency code.
            </FormHelperText>
          </FormControl>
        </Box>
      </Box>
    </Paper>
  );
};

export default InvoiceMainInfo;

import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Typography, 
  Card, 
  CardContent, 
  Button, 
  Box,
  Stack,
  alpha,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
  transition: 'all 200ms ease',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    transform: 'translateY(-2px)',
  },
}));

const ToolsGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  color: theme.palette.primary.main,
  width: 'fit-content',
}));

const tools = [
  {
    id: 1,
    name: 'Generate eInvois Data',
    description: 'Generate eInvois test data for sending to Malaysia LHDN system',
    slug: '/einvois/generate-einvois',
    icon: <DescriptionRoundedIcon sx={{ fontSize: 24 }} />,
  }
];

const Home = () => {
  return (
    <Stack spacing={3}>
      <Box>
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom
          sx={{ 
            fontWeight: 700,
            mb: 1,
          }}
        >
          Welcome to eInvois Tools
        </Typography>
        <Typography 
          variant="body1" 
          color="text.secondary"
          sx={{ maxWidth: 'md' }}
        >
          Tools for managing and submitting eInvois data. Select a tool below to get started.
        </Typography>
      </Box>
      
      <ToolsGrid>
        {tools.map(tool => (
          <StyledCard 
            key={tool.id}
            variant="outlined"
          >
            <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <IconWrapper>
                {tool.icon}
              </IconWrapper>
              <Typography 
                variant="h6" 
                component="h2" 
                gutterBottom
                sx={{ fontWeight: 600 }}
              >
                {tool.name}
              </Typography>
              <Typography 
                variant="body2" 
                color="text.secondary" 
                sx={{ mb: 'auto', pb: 2 }}
              >
                {tool.description}
              </Typography>
              <Button
                component={Link}
                to={tool.slug}
                variant="contained"
                fullWidth
                sx={{
                  mt: 'auto',
                  py: 1,
                  fontWeight: 600,
                }}
              >
                Open Tool
              </Button>
            </CardContent>
          </StyledCard>
        ))}
      </ToolsGrid>
    </Stack>
  );
};

export default Home;

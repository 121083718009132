import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import { apiRequest } from '../utils/apiConfig';
import {
  Box,
  Typography,
  Alert,
  Button,
  Card,
  CardContent,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Stack,
  alpha,
} from '@mui/material';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';

const ProductsGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 200ms ease',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    transform: 'translateY(-2px)',
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  color: theme.palette.primary.main,
  width: 'fit-content',
}));

const Billing = () => {
  const { user, creditBalance, fetchCreditBalance } = useAuth();
  const [products, setProducts] = useState([]);
  const [payments, setPayments] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchPaymentHistory = useCallback(async () => {
    setLoading(true);
    try {
      const response = await apiRequest(`/api/payment/history/${user.id}`);
      // console.log('Payment history response:', response);
      
      const paymentData = response.data || response;
      setPayments(Array.isArray(paymentData) ? paymentData : []);
      
    } catch (error) {
      console.error('Error fetching payment history:', error.message);
      setError('Failed to load payment history. Please try again later.');
      setPayments([]);
    } finally {
      setLoading(false);
    }
  }, [user.id]);

  useEffect(() => {
    document.title = 'Billing | FlowGolem';
    fetchProducts();
    fetchPaymentHistory();
    fetchCreditBalance();
  }, [fetchCreditBalance, fetchPaymentHistory]);

  const fetchProducts = async () => {
    try {
      const result = await apiRequest('/api/products');
      if (result.data) {
        setProducts(result.data);
      }
    } catch (error) {
      console.error('Error fetching products:', error.message);
      setError('Failed to load products. Please try again later.');
    }
  };

  const handlePurchase = (paymentLink) => {
    if (!paymentLink) {
      setError('Payment link not available. Please contact support.');
      return;
    }
    const url = new URL(paymentLink);
    url.searchParams.append('prefilled_email', user.email);
    window.location.href = url.toString();
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedPayments = payments.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Stack spacing={3}>
      <Box>
        <Typography variant="h4" component="h1" sx={{ mb: 1 }}>
          Billing
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ maxWidth: 'md' }}>
          Manage your credits and view payment history
        </Typography>
      </Box>

      {error && (
        <Alert severity="error">
          {error}
        </Alert>
      )}

      <StyledCard variant="outlined">
        <CardContent>
          <IconWrapper>
            <AccountBalanceWalletRoundedIcon />
          </IconWrapper>
          <Typography variant="h6" component="h2" gutterBottom>
            Your Credit Balance
          </Typography>
          <Typography variant="h4" color="primary">
            {creditBalance} Credits
          </Typography>
        </CardContent>
      </StyledCard>

      <StyledCard variant="outlined">
        <CardContent>
          <IconWrapper>
            <HistoryRoundedIcon />
          </IconWrapper>
          <Typography variant="h6" component="h2" gutterBottom>
            Payment History
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell align="right">Credits Added</TableCell>
                  <TableCell>Transaction ID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      Loading payment history...
                    </TableCell>
                  </TableRow>
                ) : paginatedPayments.length > 0 ? (
                  paginatedPayments.map((payment) => (
                    <TableRow key={payment.id}>
                      <TableCell>{formatDate(payment.created_at)}</TableCell>
                      <TableCell align="right">{payment.credit_change}</TableCell>
                      <TableCell>{`${payment.reference_id_value.substring(0, 20)}...`}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No payment history available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {payments.length > 0 && (
            <TablePagination
              component="div"
              count={payments.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
            />
          )}
        </CardContent>
      </StyledCard>

      <StyledCard variant="outlined">
        <CardContent>
          <IconWrapper>
            <ShoppingCartRoundedIcon />
          </IconWrapper>
          <Typography variant="h6" component="h2" gutterBottom>
            Add More Credits
          </Typography>
          
          <ProductsGrid>
            {products.map(product => (
              <StyledCard key={product.id} variant="outlined">
                <CardContent>
                  <Stack spacing={2} height="100%">
                    <Typography variant="h6" component="h3">
                      {product.product_name}
                    </Typography>
                    <Typography variant="h5" color="primary">
                      {product.credits} Credits
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      {product.price}
                    </Typography>
                    <Box sx={{ mt: 'auto' }}>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handlePurchase(product.stripe_payment_link)}
                        disabled={!product.stripe_payment_link}
                      >
                        Buy Now
                      </Button>
                    </Box>
                  </Stack>
                </CardContent>
              </StyledCard>
            ))}
          </ProductsGrid>
        </CardContent>
      </StyledCard>
    </Stack>
  );
};

export default Billing;

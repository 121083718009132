import React from 'react';
import {
  Stack,
  Typography,
  Button,
  Paper,
  Box
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { styled } from '@mui/material/styles';

const CodeTextField = styled('textarea')(({ theme }) => ({
  width: '100%',
  fontFamily: 'monospace',
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.grey[50],
  resize: 'vertical',
  minHeight: '200px'
}));

const SubmissionSection = ({
  submitResult,
  isLoading,
  handleSubmitToLHDN,
  onManageKeysClick
}) => {
  return (
    <Stack spacing={3}>
      <Paper variant="outlined" sx={{ p: 3, bgcolor: 'grey.50' }}>
        <Typography variant="h6" gutterBottom>
          Do you have your LHDN MyInvois API Keys?
        </Typography>

        <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
          Before submitting invoices to LHDN, you need to set up your MyInvois API credentials that let you login to their staging system.
        </Typography>

        <Button
          variant="contained"
          onClick={onManageKeysClick}
          startIcon={<SettingsIcon />}
        >
          Manage API Keys
        </Button>
      </Paper>

      <Stack spacing={3}>
        <Typography variant="body1" color="text.secondary">
          Once the eInvois data is generated, and you already set up your MyInvois API keys, click the button below to package up the documents and send to LHDN staging server.
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button 
            onClick={handleSubmitToLHDN}
            variant="contained" 
            disabled={isLoading}
            size="large"
            sx={{ minWidth: 200 }}
          >
            {isLoading ? 'Submitting...' : 'Submit to LHDN'}
          </Button>
        </Box>
      </Stack>

      {submitResult && (
        <>
          <Typography variant="h5">
            Submission Result
          </Typography>

          <Typography variant="body1" color="text.secondary">
            The data above has been sent to LHDN staging server. Read the server response below to see if any errors occurred.
          </Typography>
          
          <CodeTextField
            value={submitResult}
            readOnly
            rows={20}
          />
        </>
      )}
    </Stack>
  );
};

export default SubmissionSection;

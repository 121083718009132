import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { apiRequest } from '../../utils/apiConfig';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    InputAdornment,
    IconButton,
    Box,
    Stack,
    Typography,
    CircularProgress,
    styled,
    alpha,
} from '@mui/material';
import {
    Search as SearchIcon,
    Clear as ClearIcon,
    Business as BusinessIcon,
} from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: alpha(theme.palette.primary.main, 0.05),
    },
    '& .MuiDataGrid-row:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.02),
        cursor: 'pointer',
    },
}));

const SupplierListModal = ({ open, onClose, onSelectSupplier }) => {
    const { currentUser } = useAuth();
    const [suppliers, setSuppliers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (open) {
            loadSuppliers();
        }
    }, [open, currentUser]);

    const loadSuppliers = async () => {
        try {
            const response = await apiRequest('user-preference/suppliers', {
                method: 'GET'
            });
            if (response.success) {
                setSuppliers(response.data || []);
            }
            setLoading(false);
        } catch (err) {
            console.error('Failed to load suppliers:', err);
            setLoading(false);
        }
    };

    const handleClearSearch = () => {
        setSearchTerm('');
    };

    const handleRowClick = (params) => {
        onSelectSupplier(params.row.original);
        onClose();
    };

    const filteredSuppliers = suppliers.filter(supplier => {
        const searchFields = [
            supplier.preference_value.supplier_name,
            supplier.preference_value.supplier_tin,
            supplier.preference_value.supplier_tel,
            supplier.preference_value.supplier_address,
            supplier.preference_value.supplier_city,
        ];
        return searchFields.some(field => 
            field && field.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const columns = [
        { 
            field: 'supplier_name', 
            headerName: 'Company Name', 
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <Typography variant="body2" noWrap>
                    {params.value}
                </Typography>
            ),
        },
        {
            field: 'tin',
            headerName: 'Company TIN',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <Stack direction="row" spacing={1} alignItems="center">
                    <BusinessIcon fontSize="small" color="action" />
                    <Typography variant="body2" noWrap>
                        {params.value}
                    </Typography>
                </Stack>
            ),
        },
        { 
            field: 'address', 
            headerName: 'Address', 
            flex: 2,
            minWidth: 250,
            renderCell: (params) => (
                <Typography variant="body2" noWrap>
                    {params.value}
                </Typography>
            ),
        },
    ];

    const rows = filteredSuppliers.map(supplier => ({
        id: supplier.id,
        supplier_name: supplier.preference_value.supplier_name,
        tin: supplier.preference_value.supplier_tin,
        address: `${supplier.preference_value.supplier_address}, ${supplier.preference_value.supplier_city}`,
        original: supplier
    }));

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Select Supplier</DialogTitle>
            <DialogContent>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        placeholder="Search suppliers..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: searchTerm && (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClearSearch}
                                        edge="end"
                                        size="small"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    {loading ? (
                        <Box display="flex" justifyContent="center" p={3}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box sx={{ height: 400 }}>
                            <StyledDataGrid
                                rows={rows}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                disableSelectionOnClick
                                density="comfortable"
                                onRowClick={handleRowClick}
                            />
                        </Box>
                    )}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default SupplierListModal;

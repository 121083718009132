import React from 'react';
import {
  Stack,
  Typography,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const CodeTextField = styled(TextField)(({ theme }) => ({
  fontFamily: 'monospace',
  '& .MuiInputBase-input': {
    fontFamily: 'monospace',
    ...theme.components.MuiOutlinedInput.styleOverrides.root
  }
}));

const XmlGenerationSection = ({ 
  xmlResult, 
  setXmlResult
}) => {
  return (
    <Stack spacing={3}>
      <Typography variant="h5">
        Generated eInvois Data
      </Typography>

      <Typography variant="body1" color="text.secondary">
        Below is the XML data that eInvois system expects to receive. All the data that you input in the form above has been formatted according to LHDN structure. Feel free to edit before submitting to LHDN to help you with your testing.
      </Typography>
      
      <CodeTextField
        multiline
        fullWidth
        rows={8}
        value={xmlResult}
        onChange={(e) => setXmlResult(e.target.value)}
        sx={{ 
          '& .MuiInputBase-root': {
            fontFamily: 'monospace',
            bgcolor: 'grey.50'
          }
        }}
      />
    </Stack>
  );
};

export default XmlGenerationSection;

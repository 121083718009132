import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { apiRequest } from '../utils/apiConfig';
import {
  Box,
  Card,
  Typography,
  CircularProgress,
  Stack,
  styled,
  alpha,
} from '@mui/material';
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  color: theme.palette.primary.main,
  width: 'fit-content',
}));

const PaymentHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getSessionUserId } = useAuth();
  const hasProcessedPayment = useRef(false);

  useEffect(() => {
    const handlePayment = async () => {
      if (hasProcessedPayment.current) {
        return;
      }

      const timestamp = new Date().toISOString();
      const queryParams = new URLSearchParams(location.search);
      const sessionId = queryParams.get('session_id');
      const userId = getSessionUserId();

      if (!sessionId || !userId) {
        console.error(`[${timestamp}] Missing required parameters:`, {
          hasSessionId: !!sessionId,
          hasUserId: !!userId
        });
        hasProcessedPayment.current = true;
        window.location.href = '/billing';
        return;
      }

      try {
        hasProcessedPayment.current = true;
        
        await apiRequest('/api/payment/success', {
          method: 'POST',
          body: {
            session_id: sessionId,
            user_id: userId
          }
        });

        window.location.href = '/billing';
      } catch (error) {
        console.error(`[${timestamp}] Error processing payment:`, {
          error: error.message,
          stack: error.stack
        });
        window.location.href = '/billing';
      }
    };

    handlePayment();
  }, [location.search, navigate, getSessionUserId]);

  return (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <Typography variant="h4" component="h1">
          Payment Processing
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Please wait while we verify your payment
        </Typography>
      </Stack>

      <Card variant="outlined">
        <Stack spacing={3} p={3}>
          <IconWrapper>
            <PaymentRoundedIcon />
          </IconWrapper>

          <Stack direction="row" spacing={2} alignItems="center">
            <CircularProgress size={24} />
            <Typography variant="body1">
              Verifying payment and updating credits...
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};

export default PaymentHandler;

import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { apiRequest } from '../../utils/apiConfig';
import UserKeysModal from '../../components/UserKeysModal';
import InvoiceMainInfo from './partial-einvoisform-maininfo';
import InvoiceTaxInfo from './partial-einvoisform-taxinfo';
import InvoiceSupplierInfo from './partial-einvoisform-supplierinfo';
import InvoiceCustomerInfo from './partial-einvoisform-customerinfo';
import InvoiceLineItems from './partial-einvoisform-lineitems';
import InvoiceFinalTotals from './partial-einvoisform-finaltotals';
import XmlGenerationSection from './components/XmlGenerationSection';
import SubmissionSection from './components/SubmissionSection';
import DocumentDetailsSection from './components/DocumentDetailsSection';
import ErrorSnackbar from './components/ErrorSnackbar';
import { useEinvoisForm } from './hooks/useEinvoisForm';

import {
  Box,
  Typography,
  Link,
  Divider,
  Stack,
  Button,
} from '@mui/material';

const GenerateEinvois = () => {
  const [isKeysModalOpen, setIsKeysModalOpen] = useState(false);
  const { updateCreditBalance } = useAuth();
  const {
    formData,
    totals,
    xmlResult,
    submitResult,
    documentDetails,
    isLoading,
    error,
    isErrorVisible,
    handleInputChange,
    handleLineItemChange,
    addLineItem,
    removeLineItem,
    handleGenerateXml,
    handleSubmitToLHDN,
    handleGetDocumentDetails,
    handleErrorClose,
    setXmlResult
  } = useEinvoisForm();

  const refreshCreditBalance = async () => {
    try {
      const result = await apiRequest('/api/credits/balance');
      if (result && result.success && typeof result.data.balance === 'number') {
        updateCreditBalance(result.data.balance);
      }
    } catch (err) {
      console.error('Error fetching credit balance:', err);
    }
  };

  return (
    <Stack spacing={3}>
      <Box>
        <Typography 
          variant="h4" 
          component="h1"
          sx={{ 
            fontWeight: 700,
            mb: 1,
          }}
        >
          Generate Malaysia eInvoice
        </Typography>
        
        <Typography variant="body1" color="text.secondary">
          If you run a business in Malaysia, you need to send all your invoice data to LHDN's einvoicing system. 
          The einvoice data must be sent in a very specific XML format (adapted from the{' '}
          <Link 
            href="https://docs.peppol.eu/poac/my/" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            EU PEPPOL standard
          </Link>)
        </Typography>

        <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
          This tool helps businesses generate a properly-formatted Malaysia eInvoice, also sends it to LHDN for validation. 
          And for developers, it shows the XML content for reference
        </Typography>

        <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
          Start filling in the invoice below with your sample data to see how it works
        </Typography>
      </Box>

      <Divider />

      <form onSubmit={handleGenerateXml}>
        <Stack spacing={3}>
          <InvoiceMainInfo
            formData={formData}
            handleInputChange={handleInputChange} 
          />
          <InvoiceTaxInfo
            formData={formData}
            handleInputChange={handleInputChange} 
          />
          <InvoiceSupplierInfo
            formData={formData}
            handleInputChange={handleInputChange} 
          />
          <InvoiceCustomerInfo
            formData={formData}
            handleInputChange={handleInputChange} 
          />
          <InvoiceLineItems
            lineItems={formData.line_items}
            handleLineItemChange={handleLineItemChange}
            addLineItem={addLineItem}
            removeLineItem={removeLineItem}
          />
          <InvoiceFinalTotals
            totals={totals}
            handleInputChange={handleInputChange} 
          />

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button 
              type="submit" 
              variant="contained" 
              disabled={isLoading}
              size="large"
              sx={{ minWidth: 200 }}
            >
              {isLoading ? 'Generating...' : 'Generate eInvois Data'}
            </Button>
          </Box>
        </Stack>
      </form>

      {xmlResult && (
        <>
          <XmlGenerationSection 
            xmlResult={xmlResult}
            setXmlResult={setXmlResult}
          />

          <SubmissionSection
            submitResult={submitResult}
            isLoading={isLoading}
            handleSubmitToLHDN={handleSubmitToLHDN}
            onManageKeysClick={() => setIsKeysModalOpen(true)}
          />

          {submitResult && (
            <DocumentDetailsSection
              documentDetails={documentDetails}
              isLoading={isLoading}
              handleGetDocumentDetails={handleGetDocumentDetails}
            />
          )}
        </>
      )}

      <UserKeysModal 
        isOpen={isKeysModalOpen}
        onClose={() => setIsKeysModalOpen(false)}
      />

      <ErrorSnackbar 
        isVisible={isErrorVisible}
        error={error}
        onClose={handleErrorClose}
      />
    </Stack>
  );
};

export default GenerateEinvois;

import React from 'react';
import { useAuth } from '../context/AuthContext';
import {
  Box,
  Typography,
  TextField,
  Stack,
  Card,
  CardContent,
  styled,
  alpha,
} from '@mui/material';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  transition: 'all 200ms ease',
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  color: theme.palette.primary.main,
  width: 'fit-content',
}));

const Settings = () => {
  const { user, creditBalance } = useAuth();

  return (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <Typography variant="h4" component="h1">
          Settings
        </Typography>
        <Typography variant="body1" color="text.secondary">
          View and manage your account settings
        </Typography>
      </Stack>

      <StyledCard variant="outlined">
        <CardContent>
          <Stack spacing={3}>
            <IconWrapper>
              <AccountCircleRoundedIcon />
            </IconWrapper>
            <Typography variant="h6" component="h2">
              Account Information
            </Typography>
            
            <Stack spacing={1}>
              <Typography variant="subtitle2" component="label" color="text.secondary">
                Email Address
              </Typography>
              <Typography variant="body1">
                {user?.email}
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </StyledCard>

      <StyledCard variant="outlined">
        <CardContent>
          <Stack spacing={3}>
            <IconWrapper>
              <AccountBalanceWalletRoundedIcon />
            </IconWrapper>
            <Typography variant="h6" component="h2">
              Credit Balance
            </Typography>
            
            <Stack spacing={1}>
              <TextField
                value={creditBalance}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                fullWidth
                sx={{
                  '& .MuiInputBase-input': {
                    bgcolor: (theme) => alpha(theme.palette.action.hover, 0.3),
                    cursor: 'not-allowed',
                  },
                }}
              />
              <Typography variant="caption" color="text.secondary">
                Visit the Billing page to purchase more credits
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </StyledCard>
    </Stack>
  );
};

export default Settings;

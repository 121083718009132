import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { apiRequest } from '../../utils/apiConfig';
import {
    Typography,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    Alert,
    CircularProgress,
    Card,
    CardContent,
    CardActions,
    Stack,
    styled,
    alpha,
    Divider,
    Tooltip,
    Chip
} from '@mui/material';
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Cancel as CancelIcon,
    BusinessRounded as BusinessIcon,
    VerifiedUser as VerifiedUserIcon,
} from '@mui/icons-material';

const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    transition: 'all 200ms ease',
    '&:hover': {
        borderColor: theme.palette.primary.main,
    },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,
    width: 'fit-content',
}));

const SupplierCard = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    transition: 'all 200ms ease',
    '&:hover': {
        borderColor: theme.palette.primary.main,
        transform: 'translateY(-2px)',
    },
}));

const ManageSuppliers = () => {
    const { currentUser } = useAuth();
    const [suppliers, setSuppliers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editingSupplier, setEditingSupplier] = useState(null);
    const [isFormVisible, setIsFormVisible] = useState(false);

    const emptySupplierForm = {
        supplier_name: '',
        supplier_tin: '',
        supplier_biz_id_type: 'BRN',
        supplier_biz_id_value: '',
        supplier_sst_no: '',
        supplier_msic_code: '',
        supplier_business_activity_desc: '',
        supplier_address: '',
        supplier_state: '14',
        supplier_country: 'MYS',
        supplier_city: '',
        supplier_tel: '',
        tin_validated: 'no'
    };

    const [formData, setFormData] = useState(emptySupplierForm);
    const [msicCodeMap, setMsicCodeMap] = useState(new Map());

    useEffect(() => {
        fetch('/data/MSICSubCategoryCodes.json')
            .then(response => response.json())
            .then(data => {
                const codeMap = new Map();
                data.forEach(item => {
                    codeMap.set(item.Code, item.Description);
                });
                setMsicCodeMap(codeMap);
            })
            .catch(error => console.error('Error loading MSIC codes:', error));
    }, []);

    useEffect(() => {
        loadSuppliers();
    }, [currentUser]);

    const loadSuppliers = async () => {
        try {
            const response = await apiRequest('user-preference/suppliers', {
                method: 'GET'
            });
            if (response.success) {
                setSuppliers(response.data || []);
            } else {
                throw new Error(response.error || 'Failed to load suppliers');
            }
            setLoading(false);
        } catch (err) {
            setError(err.message || 'Failed to load suppliers');
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        if (name === 'supplier_msic_code' && msicCodeMap.has(value)) {
            setFormData(prev => ({
                ...prev,
                supplier_business_activity_desc: msicCodeMap.get(value)
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let response;
            const supplierData = {
                ...formData,
                tin_validated: editingSupplier ? formData.tin_validated : 'no'
            };

            if (editingSupplier) {
                response = await apiRequest(`user-preference/suppliers/${editingSupplier.id}`, {
                    method: 'PUT',
                    body: {
                        supplierName: formData.supplier_name,
                        supplierData: supplierData
                    }
                });
            } else {
                response = await apiRequest('user-preference/suppliers', {
                    method: 'POST',
                    body: {
                        supplierName: formData.supplier_name,
                        supplierData: supplierData
                    }
                });
            }

            if (!response.success) {
                throw new Error(response.error || 'Failed to save supplier');
            }

            await loadSuppliers();
            resetForm();
        } catch (err) {
            setError(err.message || 'Failed to save supplier');
        }
    };

    const handleEdit = (supplier) => {
        setEditingSupplier(supplier);
        setFormData(supplier.preference_value);
        setIsFormVisible(true);
    };

    const handleDelete = async (supplierId) => {
        if (window.confirm('Are you sure you want to delete this supplier?')) {
            try {
                const response = await apiRequest(`user-preference/suppliers/${supplierId}`, {
                    method: 'DELETE'
                });

                if (!response.success) {
                    throw new Error(response.error || 'Failed to delete supplier');
                }

                await loadSuppliers();
            } catch (err) {
                setError(err.message || 'Failed to delete supplier');
            }
        }
    };

    const handleValidateTIN = async (supplier) => {
        try {
            const { supplier_tin, supplier_biz_id_type, supplier_biz_id_value } = supplier.preference_value;
            
            // Call the validate-tin endpoint with all required parameters
            const validationResponse = await apiRequest(
                `einvois/validate-tin/${supplier_tin}?idType=${supplier_biz_id_type}&idValue=${supplier_biz_id_value}`,
                { method: 'GET' }
            );

            if (!validationResponse.success) {
                throw new Error(validationResponse.error || 'TIN validation failed');
            }

            // Update the supplier record with the validation status
            const updatedPreferenceValue = {
                ...supplier.preference_value,
                tin_validated: 'yes'
            };

            const updateResponse = await apiRequest(`user-preference/suppliers/${supplier.id}`, {
                method: 'PUT',
                body: {
                    supplierName: supplier.preference_value.supplier_name,
                    supplierData: updatedPreferenceValue
                }
            });

            if (!updateResponse.success) {
                throw new Error(updateResponse.error || 'Failed to update supplier');
            }

            await loadSuppliers();
            setError(null);
        } catch (err) {
            setError(err.message || 'Failed to validate TIN');
        }
    };

    const resetForm = () => {
        setFormData(emptySupplierForm);
        setEditingSupplier(null);
        setIsFormVisible(false);
        setError(null);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                <Stack spacing={1}>
                    <Typography variant="h4" component="h1">
                        Manage Suppliers
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Make sure each company has valid TIN and SSM number
                    </Typography>
                </Stack>
                <Button
                    variant="contained"
                    startIcon={isFormVisible ? <CancelIcon /> : <AddIcon />}
                    onClick={() => setIsFormVisible(!isFormVisible)}
                >
                    {isFormVisible ? 'Cancel' : 'Add Supplier'}
                </Button>
            </Stack>

            {error && (
                <Alert severity="error">
                    {error}
                </Alert>
            )}

            {isFormVisible && (
                <StyledCard variant="outlined">
                    <CardContent>
                        <Stack spacing={3}>
                            <IconWrapper>
                                <BusinessIcon />
                            </IconWrapper>
                            <Typography variant="h6" component="h2">
                                {editingSupplier ? 'Edit Supplier' : 'Add New Supplier'}
                            </Typography>
                            <Box
                                component="form"
                                onSubmit={handleSubmit}
                                sx={{
                                    display: 'grid',
                                    gap: 3,
                                    gridTemplateColumns: {
                                        xs: '1fr',
                                        sm: 'repeat(2, 1fr)'
                                    }
                                }}
                            >
                                <TextField
                                    fullWidth
                                    label="Supplier Name"
                                    name="supplier_name"
                                    value={formData.supplier_name}
                                    onChange={handleInputChange}
                                    required
                                />
                                <TextField
                                    fullWidth
                                    label="Tax ID (TIN)"
                                    name="supplier_tin"
                                    value={formData.supplier_tin}
                                    onChange={handleInputChange}
                                    required
                                />
                                <FormControl fullWidth>
                                    <InputLabel>Business ID Type</InputLabel>
                                    <Select
                                        name="supplier_biz_id_type"
                                        value={formData.supplier_biz_id_type}
                                        onChange={handleInputChange}
                                        required
                                        label="Business ID Type"
                                    >
                                        <MenuItem value="NRIC">NRIC</MenuItem>
                                        <MenuItem value="PASSPORT">Passport</MenuItem>
                                        <MenuItem value="BRN">Business Registration No. (SSM)</MenuItem>
                                        <MenuItem value="ARMY">Army</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    label="Business ID Value"
                                    name="supplier_biz_id_value"
                                    value={formData.supplier_biz_id_value}
                                    onChange={handleInputChange}
                                    required
                                />
                                <TextField
                                    fullWidth
                                    label="SST No"
                                    name="supplier_sst_no"
                                    value={formData.supplier_sst_no}
                                    onChange={handleInputChange}
                                    required
                                />
                                <TextField
                                    fullWidth
                                    label="MSIC Code"
                                    name="supplier_msic_code"
                                    value={formData.supplier_msic_code}
                                    onChange={handleInputChange}
                                    required
                                    inputProps={{
                                        list: "msic-codes"
                                    }}
                                />
                                <datalist id="msic-codes">
                                    {Array.from(msicCodeMap).map(([code, desc]) => (
                                        <option key={code} value={code}>
                                            {`${code} - ${desc}`}
                                        </option>
                                    ))}
                                </datalist>
                                <Box sx={{ gridColumn: '1 / -1' }}>
                                    <TextField
                                        fullWidth
                                        label="Business Activity"
                                        name="supplier_business_activity_desc"
                                        value={formData.supplier_business_activity_desc}
                                        slotProps={{
                                            input: {
                                                readOnly: true,
                                            }
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                bgcolor: (theme) => alpha(theme.palette.action.hover, 0.3),
                                            },
                                        }}
                                    />
                                </Box>
                                <Box sx={{ gridColumn: '1 / -1' }}>
                                    <TextField
                                        fullWidth
                                        label="Address"
                                        name="supplier_address"
                                        value={formData.supplier_address}
                                        onChange={handleInputChange}
                                        required
                                        multiline
                                        rows={3}
                                    />
                                </Box>
                                <FormControl fullWidth>
                                    <InputLabel>State</InputLabel>
                                    <Select
                                        name="supplier_state"
                                        value={formData.supplier_state}
                                        onChange={handleInputChange}
                                        required
                                        label="State"
                                    >
                                        <MenuItem value="01">Johor</MenuItem>
                                        <MenuItem value="02">Kedah</MenuItem>
                                        <MenuItem value="03">Kelantan</MenuItem>
                                        <MenuItem value="04">Melaka</MenuItem>
                                        <MenuItem value="05">Negeri Sembilan</MenuItem>
                                        <MenuItem value="06">Pahang</MenuItem>
                                        <MenuItem value="07">Pulau Pinang</MenuItem>
                                        <MenuItem value="08">Perak</MenuItem>
                                        <MenuItem value="09">Perlis</MenuItem>
                                        <MenuItem value="10">Selangor</MenuItem>
                                        <MenuItem value="11">Terengganu</MenuItem>
                                        <MenuItem value="12">Sabah</MenuItem>
                                        <MenuItem value="13">Sarawak</MenuItem>
                                        <MenuItem value="14">Wilayah Persekutuan Kuala Lumpur</MenuItem>
                                        <MenuItem value="15">Wilayah Persekutuan Labuan</MenuItem>
                                        <MenuItem value="16">Wilayah Persekutuan Putrajaya</MenuItem>
                                        <MenuItem value="17">Not Applicable</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    label="City"
                                    name="supplier_city"
                                    value={formData.supplier_city}
                                    onChange={handleInputChange}
                                    required
                                />
                                <FormControl fullWidth>
                                    <InputLabel>Country</InputLabel>
                                    <Select
                                        name="supplier_country"
                                        value={formData.supplier_country}
                                        onChange={handleInputChange}
                                        required
                                        label="Country"
                                    >
                                        <MenuItem value="MYS">Malaysia</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    label="Telephone"
                                    name="supplier_tel"
                                    value={formData.supplier_tel}
                                    onChange={handleInputChange}
                                    required
                                />
                                <Stack direction="row" spacing={2} sx={{ gridColumn: '1 / -1' }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        {editingSupplier ? 'Update Supplier' : 'Add Supplier'}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={resetForm}
                                    >
                                        Cancel
                                    </Button>
                                </Stack>
                            </Box>
                        </Stack>
                    </CardContent>
                </StyledCard>
            )}

            <StyledCard variant="outlined">
                <CardContent>
                    <Box
                        sx={{
                            display: 'grid',
                            gap: 3,
                            gridTemplateColumns: {
                                xs: '1fr',
                                sm: 'repeat(2, 1fr)',
                                md: 'repeat(3, 1fr)'
                            }
                        }}
                    >
                        {suppliers.map(supplier => (
                            <SupplierCard key={supplier.id} variant="outlined">
                                <CardContent>
                                    <Stack spacing={2}>
                                        <Typography variant="h6">
                                            {supplier.preference_value.supplier_name}
                                        </Typography>
                                        <Stack spacing={1}>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography variant="body2" color="text.secondary">
                                                    TIN: {supplier.preference_value.supplier_tin}
                                                </Typography>
                                                {supplier.preference_value.tin_validated === 'yes' && (
                                                    <Tooltip title="TIN has been validated">
                                                    <VerifiedUserIcon color="success" fontSize="small" />
                                                </Tooltip>
                                                )}
                                            </Stack>
                                            <Typography variant="body2" color="text.secondary">
                                                SST: {supplier.preference_value.supplier_sst_no}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {supplier.preference_value.supplier_address}
                                            </Typography>
                                            {supplier.preference_value.tin_validated === 'yes' && (
        <Chip
            label="TIN Validated"
            color="success"
            size="small"
            variant="outlined"
        />
    )}
                                        </Stack>
                                    </Stack>
                                </CardContent>
                                <Box sx={{ flexGrow: 1 }} />
                                <Divider />
                                <CardActions>
                                    <Button
                                        startIcon={<EditIcon />}
                                        onClick={() => handleEdit(supplier)}
                                        size="small"
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        startIcon={<DeleteIcon />}
                                        onClick={() => handleDelete(supplier.id)}
                                        size="small"
                                        color="error"
                                    >
                                        Delete
                                    </Button>
                                    {supplier.preference_value.tin_validated !== 'yes' && (
                                        <Button
                                            startIcon={<VerifiedUserIcon />}
                                            onClick={() => handleValidateTIN(supplier)}
                                            size="small"
                                            color="success"
                                        >
                                            Validate TIN
                                        </Button>
                                    )}
                                </CardActions>
                            </SupplierCard>
                        ))}
                    </Box>
                </CardContent>
            </StyledCard>
        </Stack>
    );
};

export default ManageSuppliers;

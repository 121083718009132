import React from 'react';
import {
  Paper,
  TextField,
  MenuItem,
  Box,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

const TotalTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    textAlign: 'right',
    fontWeight: 600
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.grey[50],
    '&.Mui-disabled': {
      backgroundColor: theme.palette.action.hover
    }
  },
  '& .MuiFormLabel-root': {
    fontWeight: 500
  }
}));

const CurrencyBox = styled(Box)({
  marginRight: 1,
  fontWeight: 500
});

const InvoiceFinalTotals = ({ formData, totals }) => (
  <Paper sx={{ p: 3, mb: 3 }}>
    <Typography variant="h6" sx={{ mb: 3 }}>
      Payment Details
    </Typography>

    <Box sx={{ 
      display: 'grid',
      gridTemplateColumns: { xs: '1fr', md: 'repeat(2, 1fr)' },
      gap: 3
    }}>
      <Box sx={{ maxWidth: 300 }}>
        <TextField
          select
          fullWidth
          label="Payment Method"
          id="payment-method"
          name="payment_method"
          defaultValue="01"
          required
        >
          {[
            ["01", "Cash"],
            ["02", "Cheque"],
            ["03", "Bank Transfer"],
            ["04", "Credit Card"],
            ["05", "Debit Card"],
            ["06", "e-Wallet / Digital Wallet"],
            ["07", "Digital Bank"],
            ["08", "Others"]
          ].map(([value, label]) => (
            <MenuItem key={value} value={value}>{label}</MenuItem>
          ))}
        </TextField>
      </Box>

      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: 2,
        maxWidth: 300, 
        ml: { xs: 0, md: 'auto' }
      }}>
        {[
          {
            label: "Total Before Tax",
            id: "total-before-tax",
            name: "total_before_tax",
            value: totals.totalBeforeTax
          },
          {
            label: "Total Tax Amount",
            id: "total-tax-amount",
            name: "total_tax_amount",
            value: totals.totalTaxAmount
          },
          {
            label: "Total After Tax",
            id: "total-after-tax",
            name: "total_after_tax",
            value: totals.totalAfterTax,
            isTotal: true
          }
        ].map((field) => (
          <TotalTextField
            key={field.id}
            label={field.label}
            id={field.id}
            name={field.name}
            value={field.value.toFixed(2)}
            disabled
            size="small"
            sx={field.isTotal ? {
              '& .MuiInputBase-root': {
                bgcolor: 'primary.light',
                borderColor: 'primary.main',
                '& .MuiInputBase-input': {
                  color: 'primary.dark',
                  fontWeight: 700
                }
              }
            } : undefined}
            slotProps={{
              input: {
                startAdornment: <CurrencyBox color="text.secondary">RM</CurrencyBox>
              }
            }}
          />
        ))}
      </Box>
    </Box>
  </Paper>
);

export default InvoiceFinalTotals;

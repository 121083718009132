/**
 * Utility for conditional logging based on environment
 */

const isDevelopment = process.env.ENVIRONMENT === "DEVELOPMENT";

class Logger {
  constructor() {
    this.prefix = '';
  }

  /**
   * Set a prefix for all log messages
   * @param {string} prefix - Prefix to add to all log messages
   */
  setPrefix(prefix) {
    this.prefix = prefix ? `[${prefix}] ` : '';
  }

  /**
   * Log info message in development environment
   * @param {...any} args - Arguments to log
   */
  info(...args) {
    if (isDevelopment) {
      console.log(this.prefix, ...args);
    }
  }

  /**
   * Log warning message in development environment
   * @param {...any} args - Arguments to log
   */
  warn(...args) {
    if (isDevelopment) {
      console.warn(this.prefix, ...args);
    }
  }

  /**
   * Log error message in development environment
   * @param {...any} args - Arguments to log
   */
  error(...args) {
    if (isDevelopment) {
      console.error(this.prefix, ...args);
    }
  }

  /**
   * Log debug message in development environment
   * @param {...any} args - Arguments to log
   */
  debug(...args) {
    if (isDevelopment) {
      console.debug(this.prefix, ...args);
    }
  }

  /**
   * Create a new logger instance with a specific prefix
   * @param {string} prefix - Prefix for the logger instance
   * @returns {Logger} New logger instance
   */
  static create(prefix) {
    const logger = new Logger();
    logger.setPrefix(prefix);
    return logger;
  }
}

export default Logger;

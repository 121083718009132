import { useState, useCallback, useEffect } from 'react';
import { apiRequest } from '../../../utils/apiConfig';
import Logger from '../../../utils/logger';

const logger = Logger.create('API Config');

const EINVOIS_GENERATE_XML_TOOL_SLUG = 'einvois-generate-xml';
const EINVOIS_SUBMIT_LHDN_TOOL_SLUG = 'einvois-submit-to-lhdn';

const initialFormState = {
  invoice_id: 'INV123456',
  issue_date: new Date().toISOString().split('T')[0],
  currency: 'MYR',
  global_tax_category: 'S',
  global_tax_percentage: '8',
  supplier_name: 'SYARIKAT SUPPLIER MAJU JAYA SDN. BHD.',
  supplier_tin: 'C25845632020',
  supplier_biz_id_type: 'BRN',
  supplier_biz_id_value: '201901234567',
  supplier_sst_no: 'A01-2345-67891012',
  supplier_msic_code: '63990',
  supplier_business_activity_desc: '',
  supplier_address: 'Unit 1 Jalan Pertama, Petaling Jaya 63520',
  supplier_state: '10',
  supplier_city: 'PETALING JAYA',
  supplier_country: 'MYS',
  supplier_tel: '+60120000000',
  customer_name: 'SYARIKAT PEMBELI SDN. BHD.',
  customer_tin: 'C25845632020',
  customer_biz_id_type: 'BRN',
  customer_biz_id_value: '201901234567',
  customer_sst_no: 'W00-2000-32000000',
  customer_address: '2 Jalan Kedua',
  customer_city: 'PETALING JAYA',
  customer_state: '10',
  customer_country: 'MYS',
  customer_tel: '+60129999999',
  payment_method: '01',
  line_items: [
    {
      classification: '008',
      description: '',
      quantity: 1,
      unit_price: 0,
      total: 0,
      tax_amount: 0
    }
  ]
};

export const useEinvoisForm = () => {
  const [formData, setFormData] = useState(initialFormState);
  const [totals, setTotals] = useState({
    totalBeforeTax: 0,
    totalTaxAmount: 0,
    totalAfterTax: 0
  });
  const [xmlResult, setXmlResult] = useState('');
  const [submitResult, setSubmitResult] = useState('');
  const [documentDetails, setDocumentDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const calculateTotals = useCallback(() => {
    let totalBeforeTax = 0;
    let totalTaxAmount = 0;

    formData.line_items.forEach(item => {
      totalBeforeTax += item.total;
      totalTaxAmount += item.tax_amount;
    });

    setTotals({
      totalBeforeTax,
      totalTaxAmount,
      totalAfterTax: totalBeforeTax + totalTaxAmount
    });
  }, [formData.line_items]);

  useEffect(() => {
    calculateTotals();
  }, [calculateTotals]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  }, []);

  const handleLineItemChange = (index, field, value) => {
    const newLineItems = [...formData.line_items];
    const item = { ...newLineItems[index], [field]: value };
    
    if (field === 'quantity' || field === 'unit_price') {
      const quantity = field === 'quantity' ? value : item.quantity;
      const unitPrice = field === 'unit_price' ? value : item.unit_price;
      const total = quantity * unitPrice;
      const taxPercentage = parseFloat(formData.global_tax_percentage) || 0;
      const taxAmount = (total * taxPercentage) / 100;
      
      item.total = parseFloat(total.toFixed(2));
      item.tax_amount = parseFloat(taxAmount.toFixed(2));
    }

    newLineItems[index] = item;
    setFormData(prev => ({
      ...prev,
      line_items: newLineItems
    }));
  };

  const addLineItem = () => {
    setFormData(prev => ({
      ...prev,
      line_items: [...prev.line_items, {
        classification: '008',
        description: '',
        quantity: 1,
        unit_price: 0,
        total: 0,
        tax_amount: 0
      }]
    }));
  };

  const removeLineItem = (index) => {
    if (formData.line_items.length > 1) {
      const newLineItems = formData.line_items.filter((_, i) => i !== index);
      setFormData(prev => ({
        ...prev,
        line_items: newLineItems
      }));
    }
  };

  const setErrorWithVisibility = (message, response = null) => {
    const formatErrorMessage = (message, response) => {
      let errorMessage = message;
      
      if (response?.data?.error) {
        errorMessage += `\n\nDetails: ${response.data.error}`;
      }
      if (response?.data?.details) {
        errorMessage += `\n\nDetails: ${response.data.details}`;
      }
      if (response?.data?.message) {
        errorMessage += `\n\nMessage: ${response.data.message}`;
      }
      if (response?.data?.errors) {
        const errors = Array.isArray(response.data.errors) 
          ? response.data.errors.join('\n') 
          : response.data.errors;
        errorMessage += `\n\nErrors:\n${errors}`;
      }
      
      return errorMessage;
    };

    const formattedError = formatErrorMessage(message, response);
    setError(formattedError);
    setIsErrorVisible(!!formattedError);
  };

  const handleErrorClose = () => {
    setIsErrorVisible(false);
  };

  const handleGenerateXml = async (e) => {
    e?.preventDefault();
    setErrorWithVisibility('');
    setIsLoading(true);
    try {
      const response = await apiRequest('/api/einvois/generateubl', {
        method: 'POST',
        headers: {
          'Accept': 'application/xml'
        },
        body: {
          ...formData,
          toolSlug: EINVOIS_GENERATE_XML_TOOL_SLUG,
          total_before_tax: totals.totalBeforeTax,
          total_tax_amount: totals.totalTaxAmount,
          total_after_tax: totals.totalAfterTax
        }
      });

      if (!response.success) {
        throw new Error('Failed to generate XML', { cause: response });
      }

      if (!response.data) {
        throw new Error('Received empty response from server');
      }

      const xmlString = response.data;
      logger.info('Generated XML successfully');

      setXmlResult(xmlString);
      setErrorWithVisibility('');
    } catch (err) {
      logger.error('Error generating XML:', err);
      setErrorWithVisibility('Failed to generate XML', err.cause);
      setXmlResult('');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitToLHDN = async (e) => {
    e?.preventDefault();
    setIsLoading(true);
    setDocumentDetails(null);
    try {
      const currentBase64 = window.btoa(unescape(encodeURIComponent(xmlResult)));
      
      const response = await apiRequest('/api/einvois/submit-einvoice-document', {
        method: 'POST',
        body: {
          documentXml: xmlResult,
          invoice_id: formData.invoice_id,
          toolSlug: EINVOIS_SUBMIT_LHDN_TOOL_SLUG,
          base64Content: currentBase64
        }
      });

      if (response.success) {
        setSubmitResult(JSON.stringify(response.data, null, 2));
        setErrorWithVisibility('');
        return;
      }

      // Handle error response from backend
      let errorResponse;
      if (response.error?.details) {
        // Handle validation error format
        errorResponse = {
          status: 'error',
          code: response.error.code,
          message: response.error.details[0]?.message || 'Validation Error',
          target: response.error.details[0]?.target,
          details: response.error.details
        };
      } else if (response.error) {
        // Handle simple error format
        errorResponse = {
          status: 'error',
          message: 'LHDN API Error',
          error: response.error
        };
      } else {
        // Handle other error formats
        errorResponse = {
          status: 'error',
          message: 'LHDN API Error',
          details: response.data || response
        };
      }

      setSubmitResult(JSON.stringify(errorResponse, null, 2));
      throw new Error('Failed to submit to LHDN', { cause: errorResponse });

    } catch (err) {
      logger.error('Error submitting to LHDN:', err);
      
      // If we haven't set a submitResult yet (i.e., if the error wasn't handled above)
      if (!submitResult) {
        let errorResponse;
        if (err.cause?.error?.details) {
          // Handle validation error format
          errorResponse = {
            status: 'error',
            code: err.cause.error.code,
            message: err.cause.error.details[0]?.message || 'Validation Error',
            target: err.cause.error.details[0]?.target,
            details: err.cause.error.details
          };
        } else if (err.cause?.error) {
          // Handle simple error format
          errorResponse = {
            status: 'error',
            message: 'LHDN API Error',
            error: err.cause.error
          };
        } else {
          // Handle other error formats
          errorResponse = {
            status: 'error',
            message: 'LHDN API Error',
            details: err.cause?.data || err.cause || err.message
          };
        }
        setSubmitResult(JSON.stringify(errorResponse, null, 2));
      }
      
      setErrorWithVisibility('Failed to submit to LHDN', err.cause);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetDocumentDetails = async (e) => {
    e?.preventDefault();
    setIsLoading(true);
    setDocumentDetails(null);
    try {
      const submitResultObj = JSON.parse(submitResult);
      if (!submitResultObj?.acceptedDocuments?.[0]?.uuid) {
        throw new Error('Invalid submission result or missing document uuid');
      }

      const result = await apiRequest(`/api/einvois/document-details/${submitResultObj.acceptedDocuments[0].uuid}`);
      if (result.success && result.data) {
        setDocumentDetails(result.data);
      } else {
        throw new Error('Invalid response format from document details API', { cause: result });
      }
      setErrorWithVisibility('');
    } catch (err) {
      logger.error('Error getting document details:', err);
      setErrorWithVisibility('Failed to get document details', err.cause);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    formData,
    totals,
    xmlResult,
    submitResult,
    documentDetails,
    isLoading,
    error,
    isErrorVisible,
    handleInputChange,
    handleLineItemChange,
    addLineItem,
    removeLineItem,
    handleGenerateXml,
    handleSubmitToLHDN,
    handleGetDocumentDetails,
    handleErrorClose,
    setXmlResult
  };
};

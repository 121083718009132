import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { apiRequest, API_BASE_URL } from '../utils/apiConfig';

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [creditBalance, setCreditBalance] = useState(0);

  const checkSession = useCallback(async (token) => {
    try {
      if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] Checking session with backend URL:', API_BASE_URL);};
      const response = await apiRequest('auth/session', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (response.success && response.data?.user) {
        if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] Session valid, user authenticated');};
        setUser(response.data.user);
        localStorage.setItem('userEmail', response.data.user.email);
        localStorage.setItem('userId', response.data.user.id);
        await fetchUserMetadata(response.data.user.id);
      } else {
        if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] No user in session response, signing out');};
        handleSignOut();
      }
    } catch (error) {
      console.error('[Auth] Session check failed:', error);
      handleSignOut();
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] AuthProvider mounted, checking token');};
    const token = localStorage.getItem('token');
    if (token) {
      checkSession(token);
    } else {
      setLoading(false);
    }
  }, [checkSession]);

  const fetchUserMetadata = async (userId) => {
    try {
      const response = await apiRequest('credits/balance');
      if (response.success && response.data?.balance !== undefined) {
        setCreditBalance(response.data.balance);
        localStorage.setItem('creditBalance', response.data.balance.toString());
      }
    } catch (error) {
      console.error('[Auth] Error fetching user metadata:', error);
    }
  };

  const fetchCreditBalance = async () => {
    try {
      const response = await apiRequest('credits/balance');
      if (response.success && response.data?.balance !== undefined) {
        setCreditBalance(response.data.balance);
        localStorage.setItem('creditBalance', response.data.balance.toString());
        return response.data.balance;
      }
      return null;
    } catch (error) {
      console.error('[Auth] Error fetching credit balance:', error);
      return null;
    }
  };

  const updateCreditBalance = (newBalance) => {
    setCreditBalance(newBalance);
    localStorage.setItem('creditBalance', newBalance.toString());
  };

  const signUp = async (email, password) => {
    try {
      if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] Attempting signup with backend URL:', API_BASE_URL);};
      const response = await apiRequest('auth/signup', {
        method: 'POST',
        body: { email, password }
      });
      
      if (!response.success) throw new Error(response.error);
      
      // Return success without auto sign-in
      return { data: response.data, error: null };
    } catch (error) {
      console.error('[Auth] Signup error:', error);
      return { error: error.message };
    }
  };

  const signIn = async (email, password) => {
    try {
      if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] Attempting signin with backend URL:', API_BASE_URL);};
      if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] Environment API URL:', process.env.REACT_APP_API_URL);};
      
      // First check if email is verified
      const verificationResponse = await apiRequest('auth/check-verification', {
        method: 'POST',
        body: { email }
      });

      if (!verificationResponse.success || !verificationResponse.data?.isActivated) {
        throw new Error('Please verify your email address before signing in.');
      }

      const response = await apiRequest('auth/signin', {
        method: 'POST',
        body: { email, password }
      });

      if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] Signin response received');};
      
      if (!response.success) throw new Error(response.error);

      const { data } = response;
      if (data?.session) {
        if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] Setting user session data');};
        setUser(data.session.user);
        localStorage.setItem('token', data.session.access_token);
        localStorage.setItem('userEmail', data.session.user.email);
        localStorage.setItem('userId', data.session.user.id);
        await fetchUserMetadata(data.session.user.id);
      } else {
        console.error('[Auth] No session data in response');
        throw new Error('Invalid login response');
      }

      return { data, error: null };
    } catch (error) {
      console.error('[Auth] Signin error:', error);
      return { data: null, error: error.message };
    }
  };

  const handleSignOut = () => {
    if (process.env.ENVIRONMENT === "DEVELOPMENT") {console.log('[Auth] Handling signout, clearing session data');};
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userId');
    localStorage.removeItem('creditBalance');
  };

  const signOut = async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        await apiRequest('auth/signout', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      }
      handleSignOut();
      return { error: null };
    } catch (error) {
      console.error('[Auth] Signout error:', error);
      return { error: error.message };
    }
  };

  const resetPassword = async (email) => {
    try {
      if (!process.env.REACT_APP_RESET_PASSWORD_URL) {
        throw new Error('Reset password URL is not configured');
      }

      const response = await apiRequest('auth/forgot-password', {
        method: 'POST',
        body: { 
          email,
          redirectUrl: process.env.REACT_APP_RESET_PASSWORD_URL
        }
      });

      if (!response.success) throw new Error(response.error);
      return { data: response.data, error: null };
    } catch (error) {
      console.error('[Auth] Reset password error:', error);
      return { data: null, error: error.message };
    }
  };

  // Helper functions to get session data
  const getSessionEmail = () => localStorage.getItem('userEmail');
  const getSessionUserId = () => localStorage.getItem('userId');
  const getSessionCreditBalance = () => localStorage.getItem('creditBalance');

  const value = {
    user,
    creditBalance,
    updateCreditBalance,
    fetchCreditBalance,
    signUp,
    signIn,
    signOut,
    resetPassword,
    loading,
    getSessionEmail,
    getSessionUserId,
    getSessionCreditBalance
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

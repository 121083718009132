import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from './theme';
import MainLayout from './components/MainLayout';
import PrivateRoute from './components/PrivateRoute';
import ToolRouter from './components/ToolRouter';

// Main pages
import Home from './pages/Home';
import Settings from './pages/Settings';
import Billing from './pages/Billing';
import PaymentHandler from './pages/payment_handler';

// Auth pages (no sidebar)
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

// eInvois pages
import GenerateEinvois from './pages/einvois/generate-einvois';
import ManageSuppliers from './pages/einvois/manage-suppliers';
import ManageCustomers from './pages/einvois/manage-customers';
import ManageApiKeys from './pages/einvois/manage-api-keys';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Routes>
          {/* Auth routes - no sidebar */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          {/* Protected routes with sidebar */}
          <Route path="/" element={
            <PrivateRoute>
              <MainLayout>
                <Home />
              </MainLayout>
            </PrivateRoute>
          } />
          <Route path="/settings" element={
            <PrivateRoute>
              <MainLayout>
                <Settings />
              </MainLayout>
            </PrivateRoute>
          } />
          <Route path="/billing" element={
            <PrivateRoute>
              <MainLayout>
                <Billing />
              </MainLayout>
            </PrivateRoute>
          } />
          <Route path="/payment_handler" element={
            <PrivateRoute>
              <MainLayout>
                <PaymentHandler />
              </MainLayout>
            </PrivateRoute>
          } />
          <Route path="/tools/:slug" element={
            <PrivateRoute>
              <MainLayout>
                <ToolRouter />
              </MainLayout>
            </PrivateRoute>
          } />

          {/* eInvois routes */}
          <Route path="/einvois/generate-einvois" element={
            <PrivateRoute>
              <MainLayout>
                <GenerateEinvois />
              </MainLayout>
            </PrivateRoute>
          } />
          <Route path="/einvois/manage-suppliers" element={
            <PrivateRoute>
              <MainLayout>
                <ManageSuppliers />
              </MainLayout>
            </PrivateRoute>
          } />
          <Route path="/einvois/manage-customers" element={
            <PrivateRoute>
              <MainLayout>
                <ManageCustomers />
              </MainLayout>
            </PrivateRoute>
          } />
          <Route path="/einvois/manage-api-keys" element={
            <PrivateRoute>
              <MainLayout>
                <ManageApiKeys />
              </MainLayout>
            </PrivateRoute>
          } />
        </Routes>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;

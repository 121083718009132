import React from 'react';
import {
  Snackbar,
  Alert
} from '@mui/material';

const ErrorSnackbar = ({ 
  isVisible, 
  error, 
  onClose 
}) => {
  return (
    <Snackbar 
      open={isVisible} 
      autoHideDuration={6000} 
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert 
        onClose={onClose} 
        severity="error" 
        variant="filled"
        sx={{ 
          width: '100%',
          whiteSpace: 'pre-line'
        }}
      >
        {error}
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnackbar;

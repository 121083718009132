import React, { useEffect, useState } from 'react';
import { apiRequest } from '../../utils/apiConfig';
import {
  Paper,
  Typography,
  TextField,
  MenuItem,
  Box,
  Tooltip,
  IconButton,
  Button,
  Snackbar,
  Alert,
  InputAdornment,
  Autocomplete,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import BusinessIcon from '@mui/icons-material/Business';
import SupplierListModal from './SupplierListModal';

const InvoiceSupplierInfo = ({ formData, handleInputChange }) => {
  const [msicCodeMap, setMsicCodeMap] = useState(new Map());
  const [msicOptions, setMsicOptions] = useState([]);
  const [savedSuppliers, setSavedSuppliers] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [isSupplierListOpen, setIsSupplierListOpen] = useState(false);

  useEffect(() => {
    fetch('/data/MSICSubCategoryCodes.json')
      .then(response => response.json())
      .then(data => {
        const codeMap = new Map();
        const options = data.map(item => ({
          code: item.Code,
          description: item.Description,
          label: `${item.Code} - ${item.Description}`
        }));
        data.forEach(item => {
          codeMap.set(item.Code, item.Description);
        });
        setMsicCodeMap(codeMap);
        setMsicOptions(options);
      })
      .catch(error => console.error('Error:', error));

    loadSavedSuppliers();
  }, []);

  const loadSavedSuppliers = async () => {
    try {
      const response = await apiRequest('user-preference/suppliers', {
        method: 'GET'
      });
      if (response.success) {
        setSavedSuppliers(response.data || []);
      }
    } catch (error) {
      console.error('Error loading suppliers:', error);
    }
  };

  useEffect(() => {
    if (formData.supplier_msic_code && msicCodeMap.has(formData.supplier_msic_code)) {
      const description = msicCodeMap.get(formData.supplier_msic_code);
      handleInputChange({
        target: {
          name: 'supplier_business_activity_desc',
          value: description
        }
      });
    }
  }, [formData.supplier_msic_code, msicCodeMap, handleInputChange]);

  const handleSelectChange = (event) => {
    handleInputChange({
      target: {
        name: event.target.name,
        value: event.target.value
      }
    });
  };

  const handleSupplierSelect = (supplier) => {
    Object.entries(supplier.preference_value).forEach(([key, value]) => {
      handleInputChange({
        target: {
          name: key,
          value: value
        }
      });
    });
  };

  const checkIfSupplierExists = () => {
    return savedSuppliers.some(supplier => 
      supplier.preference_value.supplier_name.toLowerCase() === formData.supplier_name.toLowerCase()
    );
  };

  const handleSaveSupplier = async () => {
    if (!formData.supplier_name) {
      setSnackbar({
        open: true,
        message: 'Please enter a supplier name',
        severity: 'error'
      });
      return;
    }

    if (checkIfSupplierExists()) {
      setSnackbar({
        open: true,
        message: 'A supplier with this name already exists',
        severity: 'warning'
      });
      return;
    }

    try {
      const supplierData = {
        ...formData,
        tin_validated: 'no'  // Set default tin_validated status
      };

      const response = await apiRequest('user-preference/suppliers', {
        method: 'POST',
        body: {
          supplierName: formData.supplier_name,
          supplierData: supplierData
        }
      });

      if (!response.success) {
        throw new Error(response.error || 'Failed to save supplier');
      }

      await loadSavedSuppliers();
      setSnackbar({
        open: true,
        message: 'Supplier saved successfully',
        severity: 'success'
      });
    } catch (err) {
      setSnackbar({
        open: true,
        message: err.message || 'Failed to save supplier',
        severity: 'error'
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <Paper 
      sx={{
        p: 3,
        mb: 3,
        bgcolor: 'info.light'
      }}
    >
      <Typography variant="h6" sx={{ mb: 3 }}>
        Seller / Supplier Information
      </Typography>

      <Box sx={{ 
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: 'repeat(2, 1fr)' },
        gap: 3
      }}>
        {[
          {
            label: "Supplier Name",
            id: "supplier-name",
            name: "supplier_name",
            placeholder: "AMS SETIA JAYA SDN. BHD.",
            tooltip: "Your business name as listed in your SSM",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setIsSupplierListOpen(true)}
                  edge="end"
                  size="small"
                  color="primary"
                >
                  <BusinessIcon />
                </IconButton>
              </InputAdornment>
            )
          },
          {
            label: "Supplier Tax ID",
            id: "supplier-tin",
            name: "supplier_tin",
            placeholder: "C24276483050",
            tooltip: "Also known as Tax Identification Number (TIN) or Income Tax Number. Must be obtained from LHDN"
          }
        ].map((field) => (
          <TextField
            key={field.id}
            fullWidth
            label={field.label}
            id={field.id}
            name={field.name}
            placeholder={field.placeholder}
            value={formData[field.name]}
            onChange={handleInputChange}
            required
            InputProps={{
              ...field.endAdornment && { endAdornment: field.endAdornment },
              ...(field.tooltip && !field.endAdornment && {
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={field.tooltip}>
                      <IconButton size="small" color="inherit">
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                )
              })
            }}
          />
        ))}

        <TextField
          id="supplier-biz-id-type"
          select
          fullWidth
          label="Supplier ID Type"
          name="supplier_biz_id_type"
          value={formData.supplier_biz_id_type}
          onChange={handleSelectChange}
          required
          helperText="Select the identity type used to register in LHDN"
        >
          <MenuItem value="NRIC">NRIC</MenuItem>
          <MenuItem value="PASSPORT">Passport</MenuItem>
          <MenuItem value="BRN">Business Registration No. (SSM)</MenuItem>
          <MenuItem value="ARMY">Army</MenuItem>
        </TextField>

        <TextField
          fullWidth
          label="Supplier ID Value"
          id="supplier-biz-id-value"
          name="supplier_biz_id_value"
          placeholder="202001234567"
          value={formData.supplier_biz_id_value}
          onChange={handleInputChange}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="The number of the identity chosen above">
                  <IconButton size="small" color="inherit">
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )
          }}
        />

        <TextField
          fullWidth
          label="Supplier SST No"
          id="supplier-sst-no"
          name="supplier_sst_no"
          placeholder="A01-2345-67891012"
          value={formData.supplier_sst_no}
          onChange={handleInputChange}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="SST registration number of the Supplier. If you are not SST-registered, put 'NA'">
                  <IconButton size="small" color="inherit">
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )
          }}
        />

        <Autocomplete
          fullWidth
          options={msicOptions}
          getOptionLabel={(option) => option.label || ''}
          value={msicOptions.find(option => option.code === formData.supplier_msic_code) || null}
          onChange={(event, newValue) => {
            handleInputChange({
              target: {
                name: 'supplier_msic_code',
                value: newValue ? newValue.code : ''
              }
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Supplier MSIC Code"
              required
              helperText="5-digit code representing your business nature"
            />
          )}
        />

        <TextField
          fullWidth
          label="Business Activity Description"
          id="supplier-business-activity-desc"
          name="supplier_business_activity_desc"
          value={formData.supplier_business_activity_desc}
          InputProps={{
            readOnly: true
          }}
          required
        />

        <TextField
          id="supplier-address"
          name="supplier_address"
          fullWidth
          label="Supplier Address"
          placeholder="Lot 66, Bangunan Merdeka, Persiaran Jaya, Kuala Lumpur, 50480"
          value={formData.supplier_address}
          onChange={handleInputChange}
          required
          rows={3}
          autoComplete="street-address"
        />

        <TextField
          select
          fullWidth
          label="Supplier State"
          id="supplier-state"
          name="supplier_state"
          value={formData.supplier_state}
          onChange={handleSelectChange}
          required
        >
          {[
            ["01", "Johor"], ["02", "Kedah"], ["03", "Kelantan"],
            ["04", "Melaka"], ["05", "Negeri Sembilan"], ["06", "Pahang"],
            ["07", "Pulau Pinang"], ["08", "Perak"], ["09", "Perlis"],
            ["10", "Selangor"], ["11", "Terengganu"], ["12", "Sabah"],
            ["13", "Sarawak"], ["14", "Wilayah Persekutuan Kuala Lumpur"],
            ["15", "Wilayah Persekutuan Labuan"], ["16", "Wilayah Persekutuan Putrajaya"],
            ["17", "Not Applicable"]
          ].map(([value, label]) => (
            <MenuItem key={value} value={value}>{label}</MenuItem>
          ))}
        </TextField>

        <TextField
          fullWidth
          label="Supplier City"
          id="supplier-city"
          name="supplier_city"
          placeholder="PETALING JAYA"
          value={formData.supplier_city}
          onChange={handleInputChange}
          required
        />

        <TextField
          select
          fullWidth
          label="Supplier Country"
          id="supplier-country"
          name="supplier_country"
          value={formData.supplier_country}
          onChange={handleSelectChange}
          required
        >
          <MenuItem value="MYS">Malaysia</MenuItem>
        </TextField>

        <TextField
          fullWidth
          label="Supplier Tel"
          id="supplier-tel"
          name="supplier_tel"
          placeholder="+60120000000"
          value={formData.supplier_tel}
          onChange={handleInputChange}
          required
        />
      </Box>

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={handleSaveSupplier}
        >
          Save Supplier
        </Button>
      </Box>

      <SupplierListModal
        open={isSupplierListOpen}
        onClose={() => setIsSupplierListOpen(false)}
        onSelectSupplier={handleSupplierSelect}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default InvoiceSupplierInfo;

import React, { useState } from 'react';
import { apiRequest } from '../../utils/apiConfig';
import {
  Paper,
  Typography,
  TextField,
  MenuItem,
  Box,
  Tooltip,
  IconButton,
  Button,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputAdornment,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import ContactsIcon from '@mui/icons-material/Contacts';
import CustomerListModal from './CustomerListModal';

const InvoiceCustomerInfo = ({ formData, handleInputChange }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [updateDialog, setUpdateDialog] = useState({
    open: false,
    existingCustomer: null
  });
  const [isCustomerListOpen, setIsCustomerListOpen] = useState(false);

  const handleCustomerSelect = (customer) => {
    Object.entries(customer.preference_value).forEach(([key, value]) => {
      handleInputChange({
        target: {
          name: key,
          value: value
        }
      });
    });
  };

  const handleSaveCustomer = async () => {
    if (!formData.customer_name) {
      setSnackbar({
        open: true,
        message: 'Please enter a customer name',
        severity: 'error'
      });
      return;
    }

    try {
      const existingCustomer = await findExistingCustomer();
      if (existingCustomer) {
        setUpdateDialog({
          open: true,
          existingCustomer
        });
        return;
      }

      await saveNewCustomer();
    } catch (err) {
      setSnackbar({
        open: true,
        message: err.message || 'Failed to save customer',
        severity: 'error'
      });
    }
  };

  const findExistingCustomer = async () => {
    try {
      const response = await apiRequest('user-preference/customers', {
        method: 'GET'
      });
      if (response.success) {
        return response.data.find(customer => 
          customer.preference_value.customer_name.toLowerCase() === formData.customer_name.toLowerCase()
        );
      }
    } catch (err) {
      console.error('Error checking existing customer:', err);
    }
    return null;
  };

  const saveNewCustomer = async () => {
    try {
      const response = await apiRequest('user-preference/customers', {
        method: 'POST',
        body: {
          customerName: formData.customer_name,
          customerData: formData
        }
      });

      if (!response.success) {
        throw new Error(response.error || 'Failed to save customer');
      }

      setSnackbar({
        open: true,
        message: 'Customer saved successfully',
        severity: 'success'
      });
    } catch (err) {
      throw err;
    }
  };

  const updateExistingCustomer = async () => {
    try {
      const response = await apiRequest(`user-preference/customers/${updateDialog.existingCustomer.id}`, {
        method: 'PUT',
        body: {
          customerName: formData.customer_name,
          customerData: formData
        }
      });

      if (!response.success) {
        throw new Error(response.error || 'Failed to update customer');
      }

      setSnackbar({
        open: true,
        message: 'Customer updated successfully',
        severity: 'success'
      });
    } catch (err) {
      setSnackbar({
        open: true,
        message: err.message || 'Failed to update customer',
        severity: 'error'
      });
    } finally {
      setUpdateDialog({ open: false, existingCustomer: null });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleCloseUpdateDialog = () => {
    setUpdateDialog({ open: false, existingCustomer: null });
  };

  return (
    <Paper 
      sx={{
        p: 3,
        mb: 3,
        bgcolor: 'success.light'
      }}
    >
      <Typography variant="h6" sx={{ mb: 3 }}>
        Customer Information
      </Typography>

      <Box sx={{ 
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: 'repeat(2, 1fr)' },
        gap: 3
      }}>
        {[
          {
            label: "Customer Name",
            id: "customer-name",
            name: "customer_name",
            placeholder: "RECOMN INTERIORS SDN. BHD.",
            tooltip: null,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setIsCustomerListOpen(true)}
                  edge="end"
                  size="small"
                  color="primary"
                >
                  <ContactsIcon />
                </IconButton>
              </InputAdornment>
            )
          },
          {
            label: "Customer Tax ID (TIN)",
            id: "customer-tin",
            name: "customer_tin",
            placeholder: "C25338011010",
            tooltip: "Also known as Tax Identification Number (TIN) or Income Tax Number. Must be obtained from LHDN"
          },
          {
            label: "Customer ID Value",
            id: "customer-biz-id-value",
            name: "customer_biz_id_value",
            placeholder: "e.g. 201801004813",
            tooltip: "The number of the identity chosen above"
          },
          {
            label: "Customer SST No",
            id: "customer-sst-no",
            name: "customer_sst_no",
            placeholder: "e.g. W00-2000-32000000",
            tooltip: "SST registration number of the Customer. If they are not SST-registered, put 'NA'"
          }
        ].map((field) => (
          <TextField
            key={field.id}
            fullWidth
            label={field.label}
            id={field.id}
            name={field.name}
            placeholder={field.placeholder}
            value={formData[field.name]}
            onChange={handleInputChange}
            required
            InputProps={{
              ...field.endAdornment && { endAdornment: field.endAdornment },
              ...(field.tooltip && !field.endAdornment && {
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={field.tooltip}>
                      <IconButton size="small" color="inherit">
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                )
              })
            }}
          />
        ))}

        <TextField
          select
          fullWidth
          label="Customer ID Type"
          id="customer-biz-id-type"
          name="customer_biz_id_type"
          value={formData.customer_biz_id_type}
          onChange={handleInputChange}
          required
          helperText='The identity used to register in LHDN'
        >
          <MenuItem value="NRIC">NRIC</MenuItem>
          <MenuItem value="PASSPORT">Passport</MenuItem>
          <MenuItem value="BRN">Business Registration No. (SSM)</MenuItem>
          <MenuItem value="ARMY">Army</MenuItem>
        </TextField>

        <TextField
          fullWidth
          label="Customer Address"
          id="customer-address"
          name="customer_address"
          placeholder="e.g. Lot 66, Bangunan Merdeka, Persiaran Jaya, Kuala Lumpur, 50480"
          value={formData.customer_address}
          onChange={handleInputChange}
          required
          rows={3}
          autoComplete="street-address"
        />

        <TextField
          fullWidth
          label="Customer City"
          id="customer-city"
          name="customer_city"
          placeholder="PETALING JAYA"
          value={formData.customer_city}
          onChange={handleInputChange}
        />

        <TextField
          select
          fullWidth
          label="Customer State"
          id="customer-state"
          name="customer_state"
          value={formData.customer_state}
          onChange={handleInputChange}
          required
        >
          {[
            ["01", "Johor"], ["02", "Kedah"], ["03", "Kelantan"],
            ["04", "Melaka"], ["05", "Negeri Sembilan"], ["06", "Pahang"],
            ["07", "Pulau Pinang"], ["08", "Perak"], ["09", "Perlis"],
            ["10", "Selangor"], ["11", "Terengganu"], ["12", "Sabah"],
            ["13", "Sarawak"], ["14", "Wilayah Persekutuan Kuala Lumpur"],
            ["15", "Wilayah Persekutuan Labuan"], ["16", "Wilayah Persekutuan Putrajaya"],
            ["17", "Not Applicable"]
          ].map(([value, label]) => (
            <MenuItem key={value} value={value}>{label}</MenuItem>
          ))}
        </TextField>

        <TextField
          select
          fullWidth
          label="Customer Country"
          id="customer-country"
          name="customer_country"
          value={formData.customer_country}
          onChange={handleInputChange}
          required
        >
          <MenuItem value="MYS">Malaysia</MenuItem>
        </TextField>

        <TextField
          fullWidth
          label="Customer Tel"
          id="customer-tel"
          name="customer_tel"
          placeholder="+60129999999"
          value={formData.customer_tel}
          onChange={handleInputChange}
          required
        />
      </Box>

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={handleSaveCustomer}
        >
          Save Customer
        </Button>
      </Box>

      <Dialog
        open={updateDialog.open}
        onClose={handleCloseUpdateDialog}
      >
        <DialogTitle>
          Update Existing Customer?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            A customer with the name "{formData.customer_name}" already exists. Would you like to update their details with the new information?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdateDialog}>
            Cancel
          </Button>
          <Button 
            onClick={updateExistingCustomer} 
            variant="contained"
          >
            Update Customer
          </Button>
        </DialogActions>
      </Dialog>

      <CustomerListModal
        open={isCustomerListOpen}
        onClose={() => setIsCustomerListOpen(false)}
        onSelectCustomer={handleCustomerSelect}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default InvoiceCustomerInfo;

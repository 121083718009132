import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import GenerateEinvois from '../pages/einvois/generate-einvois';

const ToolRouter = () => {
    const { slug } = useParams();

    // Map tool slugs to their respective components
    const toolComponents = {
        'einvois': <GenerateEinvois />
        // Add more tool mappings here as needed
    };

    // Get the component for the current slug
    const Component = toolComponents[slug];

    // If we have a component for this slug, render it
    if (Component) {
        return Component;
    }

    // If no matching component is found, redirect to home
    return <Navigate to="/" replace />;
};

export default ToolRouter;
